@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

html {
  font-family: 'Poppins', sans-serif;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 243, 243, 243;
  --background-end-rgb: 243, 243, 243;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 243, 243, 243;
    --background-end-rgb: 243, 243, 243;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeInContainer {
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}